.rc-footer {
  position: relative;
  clear: both;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 1.5;
  background-color: #000;
}
.rc-footer a {
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.rc-footer a:hover {
  color: #40a9ff;
}
.rc-footer-container {
  width: 100%;
  max-width: 1200px;
  padding: 80px 0 20px;
  margin: auto;
}
.rc-footer-columns {
  display: flex;
  justify-content: space-around;
}
.rc-footer-column {
  margin-bottom: 60px;
}
.rc-footer-column h2 {
  position: relative;
  margin: 0 auto 24px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.rc-footer-column-icon {
  margin-right: 0.5em;
  width: 22px;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
  text-align: center;
}
.rc-footer-column-icon > span,
.rc-footer-column-icon > svg,
.rc-footer-column-icon img {
  width: 100%;
  display: block;
}
.rc-footer-item {
  margin: 12px 0;
}
.rc-footer-item-icon {
  margin-right: 0.4em;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
  text-align: center;
}
.rc-footer-item-icon > span,
.rc-footer-item-icon > svg,
.rc-footer-item-icon img {
  width: 100%;
  display: block;
}
.rc-footer-item-separator {
  margin: 0 0.3em;
}
.rc-footer-bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 16px 0;
  line-height: 32px;
  font-size: 16px;
}
.rc-footer-light {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light h2,
.rc-footer-light a {
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light .rc-footer-bottom-container {
  border-top-color: #e8e8e8;
}
.rc-footer-light .rc-footer-item-separator,
.rc-footer-light .rc-footer-item-description {
  color: rgba(0, 0, 0, 0.45);
}
@media only screen and (max-width: 767.99px) {
  .rc-footer {
    text-align: center;
  }
  .rc-footer-container {
    padding: 40px 0;
  }
  .rc-footer-columns {
    display: block;
  }
  .rc-footer-column {
    display: block;
    margin-bottom: 40px;
  }
  .rc-footer-column:last-child {
    margin-bottom: 0;
  }
}
